<template>
  <KonfirmasiRakorPltuForm mode="Tambah" module="Data Konfirmasi Rakor PLTU"> </KonfirmasiRakorPltuForm>
</template>

<script>
import KonfirmasiRakorPltuForm from './form';

const KonfirmasiRakorPltuAdd = {
  name: 'KonfirmasiRakorPltuAdd',
  components: { KonfirmasiRakorPltuForm },
};

export default KonfirmasiRakorPltuAdd;
</script>
